  @media print {
    body * {
      visibility: hidden;
    }
  
    #printable-area, #printable-area * {
      visibility: visible;
    }
  
    #printable-area {
      position: fixed;
      /* left: 50%; */
      top: 0;
      width: 100%;
      transform: translate(-50%,4px);
    }
  }