.id-card {
    width: 350px;
    height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .id-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .school-name {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
  }
  .school-logo{
    position: absolute;
    top: 72px;
    right: -45px;
    transform: translate(-50%, -50%);
  }
  .school-logo img {
    width: 118px;
    height: 118px;
  }
  
  .photo-placeholder {
    position: absolute;
    top: 211px;
    right: 49px;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007bff;
    font-weight: bold;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 22em !important;
  }
  
  .line {
    margin-bottom: 10px;
    font-size: 1em;
    color: #333;
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
  
    #printable-area-st, #printable-area-st * {
      visibility: visible;
    }
  
    #printable-area-st {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }