.ring-parent{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}
.ring {
    width: 120px;
    height: 120px;
    border: 0px solid #011015;
    border-radius: 50%;
    position: absolute;
}

.ring:nth-child(1) {
    border-bottom-width: 8px;
    border-color: #f7b84b;
    animation: rotate 2s linear infinite;
}

.ring:nth-child(2) {
    border-right-width: 8px;
    border-color: #0ab39c;
    animation: rotate2 2s linear infinite;
}

.ring:nth-child(3) {
    border-top-width: 8px;
    border-color: #405189;
    animation: rotate3 2s linear infinite;
}


@keyframes rotate {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate2 {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate3 {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

