

@media print {
    body * {
        visibility: hidden;
    }

    #printable-area-st,
    #printable-area-st * {
        visibility: visible;
    }

    /* #printable-area-st {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    } */
}