

@media print {
    body * {
        visibility: hidden;
    }

    #printable-area-st,
    #printable-area-st * {
        visibility: visible;
    }

    #printable-area-st {
        width: 100%;
        position: fixed;
        left: 31.5%;
        top: 2%;
        transform: translate(0%, 0%);
    }
}