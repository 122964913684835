.IconBox {
    text-align: center;
    /* padding: 30px 10px; */
    overflow: hidden;
    position: relative;
    cursor: pointer;
    /* border: 2px dashed #868383; */
    transition: all 0.3s ease 0s;
}
.serviceBox {
    text-align: center;
    /* padding: 30px 10px; */
    overflow: hidden;
    position: relative;
    cursor: pointer;
    /* border: 2px dashed #868383; */
    transition: all 0.3s ease 0s;
}

.serviceBox:hover {
    /* background: #fff; */
    border: none;
}

.serviceBox:before {
    content: "";
    width: 35px;
    height: 35px;
    border-width: 20px;
    border-style: solid;
    border-color: var(--vz-light) var(--vz-light) rgba(1, 1, 1, 0.2) rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -40px;
    right: -40px;
    transition: all 0.3s ease 0s;
}

.serviceBox:hover:before {
    top: 0;
    right: 0;
}

.serviceBox i {
    display: block;
    font-size: 35px;
    color: rgba(var(--vz-info-rgb), var(--vz-bg-opacity));
    margin-bottom: 14px;
    transition: all 0.3s ease 0s;
}
.IconBox i {
    display: block;
    font-size: 35px;
    color: rgba(var(--vz-info-rgb), var(--vz-bg-opacity));
    margin-bottom: 14px;
    transition: all 0.3s ease 0s;
}

.serviceBox .title {
    font-size: 18px;
    font-weight: 900;
    color: var(--vz-light);
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 0 16px 0;
    transition: all 0.3s ease 0s;
}

.serviceBox .description {
    font-size: 15px;
    color: var(--vz-light);
    line-height: 25px;
    margin: 0;
    transition: all 0.3s ease 0s;
}

.serviceBox:hover i,
.serviceBox:hover .title,
.serviceBox:hover .counter-value,
.serviceBox:hover .description {
    color: var(--vz-light);
}

@media only screen and (max-width: 990px) {
    .serviceBox {
        margin-bottom: 20px;
    }
}


.sticky-header {
    position: sticky;
    top: -8px;
    z-index: 1050;
    font-size: 1.8rem;
    padding: 1rem;
}

.header-border {
    padding: 10px 0;
    position: relative;
}

.header-border::after {
    content: "";
    display: block;
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: repeating-linear-gradient(
        45deg, 
        var(--vz-purple), 
        var(--vz-purple) 5px, 
        var(--vz-light) 5px, 
        var(--vz-light) 10px
    );
}


.academy-logo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.academy-title {
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
}
.main-content-page{
    background-color: var(--vz-light);
}
.icon-transparent {
    opacity: 0.3;
    height: 70px;
    width: 70px;
  }



.bg-color-hover:hover {
  filter: brightness(75%);
  transition: 0.3s ease-in-out;
}

.custom-card {
    height: 100%;
    border-radius: 14px;
    overflow: hidden;
    background: #f8f9fa;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
  }
  
  .custom-header {
    background: linear-gradient(135deg, #007bff, #6610f2);
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 16px;
    border-bottom: none;
    font-size: 18px;
  }
  
  .user-container {
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }
  
  .user-card {
    background: #ffffff;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1), -3px -3px 6px rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    padding: 18px;
    text-align: center;
    /* width: 120px; */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
  }
  
  .user-card:hover {
    transform: translateY(-8px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .icon-container {
    width: 55px;
    height: 55px;
    background: linear-gradient(135deg, #6f42c1, #007bff);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .user-card:hover .icon-container {
    transform: scale(1.2);
  }
  
  .icon-container i {
    font-size: 26px;
    color: white;
  }
  
  .user-label {
    margin-top: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .user-count {
    background: #dc3545;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 12px;
    border-radius: 14px;
    display: inline-block;
    margin-top: 10px;
    position: absolute;
    top: -18px;
    left: 90%;
    transform: translateX(-50%);
    transition: background 0.3s ease;
  }
  
  .user-card:hover .user-count {
    background: #c82333;
  }
  